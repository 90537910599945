body,
html,
#root {
  padding: 0;
  margin: 0;
  height: 100vh;
  background-color: #f5f6f8;
}

.custom-button-style {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  padding-left: 2% !important;
  padding-right: 2% !important;
}

.whatsapp-fab-button {
  position: fixed;
  left: 10px;
  bottom: 20px;
  z-index: 10;
}
.whatsApp-icon {
  font-size: 40px !important;
  padding: 8px;
  border-radius: 100%;
  background-color: #25d366;
  color: #fff;
}

a {
  text-decoration: none;
  color: inherit;
}
